<template>
  <div style="margin-top: 2rem;">
    <Main style="margin-top: 2rem;font-family: Avenir Roman">
        <a-row :gutter="25">
            <a-col
              :xxl="24" 
              :lg="24" 
              :md="24"
              :xs="24"
              class="mb-4"
            >
              <span class="title-event-view">{{eventProp.data?.title}}</span>
            </a-col>
            <br><br>
            <a-col
              :xxl="8" 
              :lg="8" 
              :md="10"
              :xs="24"
            >
                <ValidationDescriptionComponent
                  :img='eventProp.data?.front_page'
                  :description="eventProp.data?.description"/>
            </a-col>
            <a-col
              :xxl="16"
              :lg="16" 
              :md="12"
              :xs="24"
            >
              <sdCards class="card">
                <ValidationEventsDetailsComponent
                  v-on:emitShowDinamicForm="emitShowDinamicForm"
                  :dynamicForm="eventProp.data.dynamic_form"
                  :event="dataDOM.dataEvent"/>
                <br>
                <DynamicFormComponent
                  v-if="eventProp.showDinamicForm && eventProp.data.dynamic_form != null"
                  :dynamic_form="eventProp.data.dynamic_form" />
              </sdCards>

              <template v-if='eventProp?.data?.subevents?.length > 0'>
              
                <sdCards class="card" v-for='sub in eventProp?.data?.subevents' :key = "sub.id">
                  <sub-event
                    :dynamicForm="sub?.dynamicform"
                    :event="sub"
                    :workCenters='dataDOM.worksCenters'

                  />

                </sdCards>
              </template>


            </a-col>
            <a-col
              v-if="eventProp.data.status == 'Por aprobar'"
              :xxl="24"
              :lg="24" 
              :md="24"
              :xs="24"
              style="text-align: center;"
            >
              <button class="button-normal-outlet mr-3" v-on:click="handleOKModal">
                <span class="title-normal">No validar</span>
              </button>
              <button class="button-normal" v-on:click="() => { handleSendEventValidation(true) }">
                <span class="title-normal">Validar</span>
              </button>
            </a-col>
        </a-row>
        <sdModal
          title="No validar evento"
          type="primary"
          :onOk="handleOKModal"
          :visible="eventProp.visible"
          :onCancel="handleCancelModal"
          class="title-bold-normal">
          <div>
            <a-row>
              <a-col 
                :xxl="24"
                :lg="24" 
                :md="24"
                :xs="24">
                <FormValidationWrap>
                  <VerticalFormStyleWrap>
                    <a-form
                      name="sDash_validation-form"
                      ref="formRef"
                      :model="modelForm"
                      :rules="rules"
                      :layout="formState.layout"
                    >
                      <a-form-item
                        ref="not_approved_reason"
                        label="Razón por la cual el evento no será validado"
                        name="not_approved_reason"
                        class="form-font"
                      >
                        <a-textarea placeholder="" v-model:value="modelForm.not_approved_reason"  :rows="4" class="input-field" />
                      </a-form-item>
                    </a-form>
                  </VerticalFormStyleWrap>
                </FormValidationWrap>
              </a-col>
              <a-col 
                style="text-align: center;"
                :xxl="24"
                :lg="24" 
                :md="24"
                :xs="24">
                <button class="button-normal-outlet mr-2" v-on:click="handleCancelModal">
                  <span class="title-normal">Cancelar</span>
                </button>
                <button class="button-normal" v-on:click="() => { handleSendEventValidation(false) }">
                  <span class="title-normal">Enviar</span>
                </button>
              </a-col>
            </a-row>
          </div>
        </sdModal>
    </Main>
  </div>
</template>

<script>
import { Main } from '../styled';
import { useStore } from 'vuex';
import { defineAsyncComponent, reactive, ref } from 'vue';
import { useRoute, useRouter } from "vue-router";
import { Notification } from 'ant-design-vue';
import { FormValidationWrap, VerticalFormStyleWrap } from './Style';
import { getDetailsEvent, approveEvent } from '@/services/events';
import { formatDate, removeSeconds } from '@/utility/utility';

const ValidationEventsDetailsComponent = defineAsyncComponent(() => import('./overview/details/formDetails'));
const ValidationDescriptionComponent = defineAsyncComponent(() => import('./overview/details/ValidationDescriptionComponent'));
const DynamicFormComponent = defineAsyncComponent(() => import('./overview/details/dynamicForm'));
const SubEvent = defineAsyncComponent(()=>import('./overview/details/SubEvents'))

export default {
  name: 'ValidationEventsDetailsView',
  components: {
    Main,
    ValidationEventsDetailsComponent,
    ValidationDescriptionComponent,
    FormValidationWrap,
    VerticalFormStyleWrap,
    DynamicFormComponent,
    SubEvent
  },

  async setup() {
    const router = useRouter();
    const { dispatch } = useStore();
    const store = useStore();
    dispatch('changeTitleHeader', "Validación de eventos");

    const eventProp = reactive({
      data: [],
      visible: false,
      showDinamicForm: false
    })

    const formRef = ref();
    
    const formState = reactive({
      layout: 'vertical',
    });

    const modelForm = reactive({
      not_approved_reason: '',
      approved: true
    });

    const rules = {
      not_approved_reason: [
        {
          required: true,
          trigger: 'blur',
          message: 'Este campo es requerido',
          type: 'string',
        },
      ],
    };

    const getDetailsEventFunc = async () => {
      let events = [];
      await getDetailsEvent(useRoute().params.id, true).then((response) => {
        if(response.success) {
          eventProp.data = JSON.parse(JSON.stringify(response.event));
          events = setEventsJsonByLabels(eventProp.data);
        }
      }).catch(() => {
        //console.log(error);
      })
      return events;

    }

    const setEventsJsonByLabels = (event) => {
      let generalInformation = [];
      let sedes = [];
      let CTData = [];
      switch(event.type){
        case 'normal':
          generalInformation = getGeneralInformationData(event);
          sedes =  getSedesData(event);
          CTData = getResponsableEventData(event);
        break;
        case 'benefits':
          generalInformation = getGeneralDataBenefits(event);
          sedes =  getSedesData(event);
        break;
        case 'other':
          generalInformation = getGeneralDataOther(event);
        break;
        default:
        break;
      }
      return [generalInformation, sedes, CTData];
    }

    const getGeneralDataBenefits = (event) => {
      return {
        title: "Información general",
        fields: [
          {
            label: 'Área que creó el evento',
            value: event.creator_area || '-'
          },
          {
            label: 'Fecha del evento',
            value: `${formatDate(event.event_date)}, ${event.event_hour}hrs.`
          },
          {
            label: 'Fecha de creación del evento',
            value: `${formatDate(event.date_event_create)}`
          },
          {
            label: 'Fecha de inicio del registro',
            value: formatDate(event.start_register_date)
          },
          {
            label: 'Fecha de finalización del registro',
            value: formatDate(event.end_register_date)
          },
        ]
      };
    }

    const getGeneralDataOther = (event) => {
      return {
        title: "Información general",
        fields: [
          {
            label: 'Área que creó el evento',
            value: event.creator_area || '-'
          },
          {
            label: 'Fecha del evento',
            value: `${formatDate(event.event_date)}, ${event.event_hour}hrs.`
          },
          {
            label: 'Fecha de creación del evento',
            value: `${formatDate(event.date_event_create)}`
          },
        ]
      };
    }

    const getGeneralInformationData = (event) => {
      const fields = [
        // {
        //   label: 'Responsable del evento',
        //   value: event.responsible.name + " " + event.responsible.last_name + " " + event.responsible.second_last_name
        // },
        { label: 'Área que creó el evento', value: event.creator_area || '-' },
        {
          label: 'Fecha de inicio del evento',
          value: event.event_date ? `${formatDate(event.event_date)}, ${removeSeconds(event.event_hour)}hrs.` : 'Sin definir'
        },
        {
          label: 'Fecha de finalización del evento',
          value: event.end_date ? `${formatDate(event.end_date)}, ${removeSeconds(event.end_time)}hrs.` : 'Sin definir'
        },
        { label: 'Fecha de inicio del registro', value: formatDate(event.start_register_date)},
        { label: 'Fecha de finalización del registro', value: formatDate(event.end_register_date) },
        { label: 'Cantidad máxima de participantes', value: event.amount_participants || 'Sin límite' },
        //{ label: 'Hora de inicio del evento', value: `${event.event_hour || '-'}hrs`  },
        //{ label: 'Fecha de creación del evento', value: `${formatDate(event.date_event_create)}` },
        {
          label: '¿El evento general tendrá varios eventos agrupados?',
          value: event.grouped_events ? 'Si' : 'No'
        },
      ];
      if (event.responsible) {
        fields.unshift(
          {
            label: 'Responsable del evento',
            value: event.responsible.name + " " + event.responsible.last_name + " " + event.responsible.second_last_name
          }
        )
      }
      return {
        title: "Información general",
        fields
      };
    }

    const getSedesData = (event) => {
      let sedesJson = {
        title: "Sedes",
        fields: [],
        fieldsFiles: JSON.parse(event.files)
      }
      event.headquarters.forEach(element => {
        let sede =  "";
        let type = "";
        if(element.type == "face-to-face"){
          type = "Presencial";
          sede = `${element.name}, ${element.street}, ${element.suburb ? element.suburb+',' : element.other_suburb ? element.other_suburb+',' :''} ${element.municipality ? element.municipality+',' : ''} ${element.postal_code ? element.postal_code : ''}`
        } else if(element.type == "virtual"){
          type = "Virtual";
          sede = `${element.virtual_platform}, ${element.link}`
        } else {
          type = "Ambas (vitual y presencial)";
          sede = `${element.virtual_platform}, ${element.link}, ${element.name}, ${element.street}, ${element.suburb ? element.suburb+',' : element.other_suburb ? element.other_suburb+',' :''} ${element.municipality ? element.municipality+',' : ''} ${element.postal_code ? element.postal_code : ''}`
        }
        sedesJson.fields.push([
            {
              label: type,
              value: sede
            },
          ])
      });
      return sedesJson;
    }

    const getResponsableEventData = (event) => {
      return {
        title: "",
        fields: [
          {
            label: '¿A quién va dirigido el evento?',
            value:  `${event.admin_can_register == 0 ? "" : "Personal en centro de trabajo administrativo"} ${event.edu_can_register == 0 ? "" : "Estructura educativa"} ${event.student_can_register == 0 ? "" : "Estudiantes"} ${event.citizen_can_register == 0 ? "" : "Ciudadanos"} `
          },
          {
            label: '¿Solo el Director de CT puede registrar?',
            value: event.director_register ? "Sí" : "No"
          },
          {
            label: '¿Solo el Administrador de Área puede registrar?',
            value:  event.only_admin_register ? "Sí" : "No"
          },
          {
            label: '¿El evento tiene actividades?',
            value: event.activities ? "Sí" : "No"
          }
        ]
      };
    }

    const handleOKModal = () => {
      eventProp.visible = true;
    }

    const handleCancelModal = () => {
      eventProp.visible = false;
    }

    const handleSendEventValidation = (approved) => {
      if(approved) {
        modelForm.approved = approved;
        modelForm.not_approved_reason = "";
        sendApproveEvent();
      } else {
        formRef.value
        .validate()
        .then(() => {
          modelForm.approved = approved;
          sendApproveEvent();
        })
        .catch(() => {
        });
      }
    };

    const sendApproveEvent = () => {
      approveEvent(eventProp.data.id, modelForm).then((response) => {
        if(response.success){
          showNotificationAndRedirect('success', 'Éxito', response.message);
        } else {
          showNotificationAndRedirect('error', 'Error', response.message);
        }
      }).catch(() => {
        showNotificationAndRedirect('error', 'Error', 'Oops ocurrió un error inesperado');
      })
    }


    const showNotificationAndRedirect = (type, message, description) => {
      Notification[type]({
        message,
        description,
      });
      if (type == 'success') {
        eventProp.visible = false;
        setTimeout(() => {
          router.push({ path: `/validation/index` })
        }, 2000);
      }
    }

    const emitShowDinamicForm = (value) => {
      eventProp.showDinamicForm = value;
    }
    const getWorkCenters = async () => {
      let workCenters = store._state.data.userData.cct;
      return JSON.parse(JSON.stringify(workCenters));
    };

    const dataDOM = reactive({
      dataEvent: await getDetailsEventFunc(),
      worksCenters: await getWorkCenters()
    });


    return {
      dataDOM,
      eventProp,
      formState,
      rules,
      formRef,
      modelForm,
      handleOKModal,
      handleCancelModal,
      handleSendEventValidation,
      emitShowDinamicForm
    };
  },
};
</script>
<style scoped lang="sass">
.title-event-view
  font-size: 18px
  font-weight: bold
.button-normal
  background: #3FA7E1
  color: #fff
  border-radius: 20px
  border: none
  padding: 8px 15px 8px 15px
  cursor: pointer
.button-normal-outlet
  background: none
  color: #636668
  border-radius: 20px
  border: 1px solid #636668
  padding: 8px 15px 8px 15px
  cursor: pointer
.form-font
  font-family: Nutmeg
  &.title
    font-weight: bold
    padding: 0
.input-field
  border-radius: 30px
.card
  box-shadow: 0px 3px 6px #00000029
  border-radius: 20px
</style>
